/*!

=========================================================
* Material Dashboard PRO React - v1.10.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import classnames from "classnames";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import CustomInput from "../customInput/CustomInput";
import GridContainer from "../grid/GridContainer";
import GridItem from "../grid/GridItem";
import styles from "../../../assets/jss/components/base/customSelectStyle";

const newStyles = {
	...styles,
	formControlMargins: {
		margin: "3px 0 !important",
	},
	gridContainer: {
		justifyContent: "center",
	},
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;
	const { t } = useTranslation();
	return (
		<CustomInput
			formControlProps={{
				fullWidth: true,
			}}
			inputProps={{
				value: filterValue || "",
				onChange: (e) => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				},
				placeholder: `${t("Search")} (${count} ${t("records")}...)`,
			}}
		/>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
	const [numberOfRows, setNumberOfRows] = React.useState(10);
	const [pageSelect, handlePageSelect] = React.useState(0);
	const classes = useStyles();
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	const { t } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		state,
		visibleColumns,
		nextPage,
		pageOptions,
		pageCount,
		previousPage,
		canPreviousPage,
		canNextPage,
		setPageSize,
		gotoPage,
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: { pageSize: 10, pageIndex: 0 },
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination
	);

	// We don't want to render all of the rows for this example, so cap
	// it for this use case
	// const firstPageRows = rows.slice(0, 10);
	let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
		function () {}
	);
	let numberOfRowsData = [5, 10, 20, 25, 50, 100];
	return (
		<>
			<div className="ReactTable -striped -highlight">
				<div className="pagination-top"></div>
				<table {...getTableProps()} className="rt-table">
					<thead className="rt-thead -header">
						{headerGroups.map((headerGroup) => (
							<tr
								{...headerGroup.getHeaderGroupProps()}
								className="rt-tr"
								key={headerGroup.id}
							>
								{headerGroup.headers.map((column, key) => (
									<th
										key={key}
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={classnames("rt-th rt-resizable-header", {
											"-cursor-pointer": headerGroup.headers.length - 1 !== key,
											"-sort-asc": column.isSorted && !column.isSortedDesc,
											"-sort-desc": column.isSorted && column.isSortedDesc,
										})}
									>
										<div className="rt-resizable-header-content">
											{column.render("Header")}
										</div>
										{/* Render the columns filter UI */}
										<div>
											{headerGroup.headers.length - 1 === key
												? null
												: column.canFilter
												? column.render("Filter")
												: null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className="rt-tbody">
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									key={i}
									{...row.getRowProps()}
									className={classnames(
										"rt-tr",
										{ " -odd": i % 2 === 0 },
										{ " -even": i % 2 === 1 }
									)}
								>
									{row.cells.map((cell, index) => {
										return (
											<td
												key={index}
												{...cell.getCellProps()}
												className="rt-td"
											>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className="pagination-bottom">
					<div className="-pagination">
						<div className="-previous">
							<button
								type="button"
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
								className="-btn"
							>
								{t("Previous")}
							</button>
						</div>
						<div className="-center">
							<GridContainer className={classes.gridContainer}>
								<GridItem xs={12} sm={6} md={4}>
									<FormControl
										fullWidth
										className={
											classes.selectFormControl +
											" " +
											classes.formControlMargins
										}
									>
										<Select
											MenuProps={{
												className: classes.selectMenu,
											}}
											classes={{
												select: classes.select,
											}}
											value={pageSelect}
											onChange={(event) => {
												gotoPage(event.target.value);
												handlePageSelect(event.target.value);
											}}
											inputProps={{
												name: "pageSelect",
												id: "page-select",
											}}
										>
											{pageSelectData.map((prop, key) => {
												return (
													<MenuItem
														key={key}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected,
														}}
														value={key}
													>
														{t("Page")} {key + 1}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<FormControl
										fullWidth
										className={
											classes.selectFormControl +
											" " +
											classes.formControlMargins
										}
									>
										<Select
											MenuProps={{
												className: classes.selectMenu,
											}}
											classes={{
												select: classes.select,
											}}
											value={numberOfRows}
											onChange={(event) => {
												setPageSize(event.target.value);
												setNumberOfRows(event.target.value);
											}}
											inputProps={{
												name: "numberOfRows",
												id: "number-of-rows",
											}}
										>
											{numberOfRowsData.map((prop) => {
												return (
													<MenuItem
														key={prop}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected,
														}}
														value={prop}
													>
														{prop} {t("rows")}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</GridItem>
							</GridContainer>
						</div>
						<div className="-next">
							<button
								type="button"
								onClick={() => nextPage()}
								disabled={!canNextPage}
								className="-btn"
							>
								{t("Next")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
